import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'
import { Company } from '../../../types/company'
import Button from '../../molecules/button'
import CompanyCard from '../../molecules/company-card'
import { Title, Wrapper } from './directory-categories'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
  gap: 2rem;
  margin-top: 3rem;
`

const AdContainer = styled.div`
  position: relative;
  height: 100%;
  min-height: 30rem;
  border-radius: 0.8rem;
  overflow: hidden;
`

const AdImage = styled(Img)`
  height: 100%;
`

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-2px);
  }

  > * {
    height: 100%;
  }
`

interface FluidObject {
  aspectRatio: number
  src: string
  srcSet: string
  sizes: string
  base64?: string
  tracedSVG?: string
  srcWebp?: string
  srcSetWebp?: string
  media?: string
}

interface DirectoryAd {
  id: string
  image: {
    fluid: FluidObject
    title: string
  }
  buttonLabel: string
  link: string
  order: number
}

type ListItem = JSX.Element

interface DirectoryListingsProps {
  className?: string
  companies?: Company[]
  ads?: DirectoryAd[]
}

const DirectoryListings = ({
  className,
  companies = [],
  ads = [],
}: DirectoryListingsProps) => {
  const getCompanySlug = (name: string) => {
    return name.toLowerCase().replace(/[^a-z0-9]+/g, '-')
  }

  const renderItems = (): ListItem[] => {
    const items: ListItem[] = []
    const sortedAds = [...ads].sort((a, b) => a.order - b.order)
    let adIndex = 0

    companies.forEach((company, index) => {
      // Add company
      items.push(
        <StyledLink
          key={company.id}
          to={`/directory/${getCompanySlug(company.companyName || '')}`}
        >
          <CompanyCard company={company} />
        </StyledLink>,
      )

      // Add ad after 2 companies and then every 7 companies
      if (
        (index === 1 || (index > 1 && (index - 1) % 7 === 0)) &&
        adIndex < sortedAds.length
      ) {
        const ad = sortedAds[adIndex]
        items.push(
          <AdContainer key={ad.id}>
            <AdImage fluid={ad.image.fluid} alt={ad.image.title} />
            <ButtonWrapper>
              <Button
                href={ad.link}
                visualStyle='Secondary'
                target='_blank'
                rel='noopener noreferrer'
              >
                {ad.buttonLabel}
              </Button>
            </ButtonWrapper>
          </AdContainer>,
        )
        adIndex++
      }
    })

    return items
  }

  return (
    <Wrapper className={className}>
      <Grid>{renderItems()}</Grid>
    </Wrapper>
  )
}

export default DirectoryListings
